export const MAP_COLORS = {
  light: {
    BACKGROUND: "#ffffff",
    LAND: {
      UNVISITED: "#eff0f0",
      VISITED: {
        normal: "#333333",
        hover: "#1a1a1a",
      },
    },
    WATER: "#d2d5d5",
    BOUNDARY: "#ababab",
    ANNOTATION: "#545454",
  },
  dark: {
    BACKGROUND: "#1a1a1a",
    LAND: {
      UNVISITED: "#2e2e2e",
      VISITED: {
        normal: "#cccccc",
        hover: "#e6e6e6",
      },
    },
    WATER: "#242424",
    BOUNDARY: "#6e6e6e",
    ANNOTATION: "#ababab",
  },
}

export const COUNTRIES_VISITED = [
  "Austria",
  "Azerbaijan",
  "Belgium",
  "Canada",
  "China",
  "Croatia",
  "Czechia",
  "Denmark",
  "Ecuador",
  "Estonia",
  "Finland",
  "France",
  "Georgia",
  "Germany",
  "Greece",
  "Indonesia",
  "Italy",
  "Japan",
  "Kyrgyzstan",
  "Liechtenstein",
  "Luxembourg",
  "Malaysia",
  "Mexico",
  "Mongolia",
  "Montenegro",
  "Netherlands",
  "Oman",
  "Panama",
  "Peru",
  "Philippines",
  "Russia",
  "Singapore",
  "South Korea",
  "Switzerland",
  "Taiwan",
  "Thailand",
  "Turkey",
  "Turkmenistan",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uzbekistan",
  "Vietnam",
]
